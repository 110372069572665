import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import BusinessLearnMore from './pages/BusinessLearnMore';
import ProviderLearnMore from './pages/ProviderLearnMore';
import Registration from './pages/Registration';
import LoginPage from './components/LoginPage';
import ProviderDashboard from './components/ProviderDashboard';
import BusinessDashboard from './components/BusinessDashboard';
import AuthWrapper from './components/AuthWrapper';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" replace />;
};

function App() {
  console.log('App component rendered');

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex flex-col">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/business/learn-more" element={<BusinessLearnMore />} />
            <Route path="/providers/learn-more" element={<ProviderLearnMore />} />
            <Route path="/register/:userType" element={<Registration />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/provider-dashboard"
              element={
                <AuthWrapper>
                  <ProviderDashboard />
                </AuthWrapper>
              }
            />
            <Route
              path="/business-dashboard"
              element={
                <AuthWrapper>
                  <BusinessDashboard />
                </AuthWrapper>
              }
            />
           <Route path="*" element={<Navigate to="/login" replace />} /> 
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;